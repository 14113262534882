/* eslint react/jsx-key: off */
import * as React from 'react';
import {
  CloneButton,
  Edit,
  email,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  ShowButton,
  SimpleForm,
  TextInput,
  Toolbar,
  TopToolbar,
  usePermissions,
  useSaveContext,
} from 'react-admin';

import Aside from './Aside';

interface Props {
  [key: string]: any;
}
/**
 * Custom Toolbar for the Edit form
 *
 * Save with undo, but delete with confirm
 */
const GuestEditToolbar: React.FC<Props> = (props) => {
  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }} {...props}>
      <SaveButton />
    </Toolbar>
  );
};

const EditActions = () => (
  <TopToolbar>
    <CloneButton className="button-clone" />
    <ShowButton />
  </TopToolbar>
);

const GuestEditForm = () => {
  const { permissions } = usePermissions();
  const { save } = useSaveContext();

  return (
    <SimpleForm toolbar={<GuestEditToolbar />}>
      <TextInput source="first_name" validate={required()} />
      <TextInput source="last_name" validate={required()} />
      <TextInput source="email" validate={[required(), email()]} />
      <ReferenceInput source="language" reference="language" validate={required()}>
        <SelectInput optionText="name" source="id" />
      </ReferenceInput>
    </SimpleForm>
  );
};
const GuestEdit = () => {
  return (
    <Edit aside={<Aside />} actions={<EditActions />} mutationMode="pessimistic">
      <GuestEditForm />
    </Edit>
  );
};

export default GuestEdit;
