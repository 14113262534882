import DefaultIcon from '@mui/icons-material/ViewList';
import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import { useCreatePath, useGetResourceLabel } from 'ra-core';
import { LayoutProps } from 'ra-ui-materialui';
import { createElement } from 'react';
import {
  AppBar,
  AppBarProps,
  Layout,
  Logout,
  Menu,
  MenuItemLink,
  UserMenu,
  useLogout,
  usePermissions,
  useResourceDefinitions,
} from 'react-admin';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Role_Enum } from 'src/utils/enum';
import { ReactComponent as LogoIcon } from '../images/logo.svg';
import BackButton from './BackButton';

export const LayoutWrapper = (props: LayoutProps): JSX.Element => {
  return (
    <>
      <Layout
        sx={{ '& .RaLayout-content': { paddingRight: 4, paddingLeft: 4 } }}
        {...props}
        appBar={CustomAppBar}
        menu={CustomMenu}
      />
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  );
};

const CustomAppBar = (props: AppBarProps): JSX.Element => {
  const isLargeEnough = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));

  return (
    <AppBar {...props} color="secondary" position={'fixed'} elevation={1} userMenu={<CustomUserMenu />}>
      <BackButton />
      <Typography
        variant="h6"
        color="inherit"
        sx={{
          flex: 1,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
        id="react-admin-title"
      />

      {isLargeEnough && (
        <Box
          sx={{
            flex: 1,
            textAlign: 'center',
            '& svg': {
              width: '112px',
              height: '20px',
              '@media (min-width:600px)': {
                width: '160px',
                height: '17.5px',
              },
            },
          }}
        >
          <LogoIcon style={{ height: '36px', width: 'auto' }} fill="orange" />
        </Box>
      )}
      {isLargeEnough && <Box component="span" sx={{ flex: 1 }} />}
    </AppBar>
  );
};

const CustomUserMenu = () => (
  <UserMenu>
    <Logout />
  </UserMenu>
);

/**
 *       <Resource name="key_view" {...key_views} />
      <Resource name="schedule" {...schedules} />
      <Resource name="key" {...keys} />
      <Resource name="room" {...rooms} />
      <Resource name="reservation" {...reservations} />
      <Resource name="guest" {...guests} />
      <Resource name="user" {...users} />
      <Resource name="group" {...groups} />
      <Resource name="object" {...controls} />
      <Resource name="sync" {...syncs} />
      <Resource name="mail" {...mails} />
      <Resource name="mail_template" {...mailTemplates} />
      <Resource name="setting" {...settings} />
      <Resource name="log" {...logs} />
 */

const activeMenu = {
  superuser: ['key_view', 'schedule', 'key', 'room', 'reservation', 'guest'],
  user: ['key_view'],
  master: ['*'],
};
const CustomMenu = () => {
  const resources = useResourceDefinitions();
  const { permissions }: { permissions: string } = usePermissions();

  const menus =
    permissions === Role_Enum.Master
      ? activeMenu.master
      : permissions === Role_Enum.Superuser
      ? activeMenu.superuser
      : activeMenu.user;
  const getResourceLabel = useGetResourceLabel();
  const createPath = useCreatePath();
  const logout = useLogout();
  const handleLogoutClick = () => logout();
  return (
    <Menu>
      {Object.keys(resources)
        .filter((name) => resources[name].hasList && (menus[0] === '*' || menus.includes(name)))
        .map((name) => (
          <MenuItemLink
            key={name}
            to={createPath({
              resource: name,
              type: 'list',
            })}
            state={{ _scrollToTop: true }}
            primaryText={getResourceLabel(name)}
            leftIcon={resources[name].icon ? createElement(resources[name].icon) : <DefaultIcon />}
          />
        ))}
      <MenuItemLink to={'#'} primaryText={'Logout'} onClick={handleLogoutClick} leftIcon={<DefaultIcon />} />
    </Menu>
  );
};
