import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDataProvider, useRecordContext } from 'react-admin';

export const LogView = (props: any) => {
  const record = useRecordContext(props);
  const dataProvider = useDataProvider();
  const [loxoneLogs, setLoxoneLogs] = useState<any[]>([]);
  const [otherLogs, setOtherLogs] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (record.lx_user_uuid && record.lx_user_uuid !== '') {
      fetchLog(record.lx_user_uuid);
    }
  }, [record]);

  const fetchLog = async (lx_user_uuid: string) => {
    try {
      setIsLoading(true);
      const response = await dataProvider.getList('log', {
        filter: { 'message@_ilike': `%${lx_user_uuid}%` },
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'created_at', order: 'DESC' },
      });

      const response2 = await dataProvider.getList('log', {
        filter: { 'message@_ilike': `%${record.confirmation_number}%` },
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'created_at', order: 'DESC' },
      });
      setIsLoading(false);
      const logs = [
        ...response.data.filter((log: any) => !log.message.includes('getuserlist2')),
        ...response2.data.filter(
          (log: any) =>
            !log.message.includes('getuserlist2') && !log.message.includes('Received webhook from SNT Data'),
        ),
      ];

      setLoxoneLogs(logs.sort((a: any, b: any) => moment(b.created_at).diff(moment(a.created_at))));
      // setOtherLogs(response.data.filter((log: any) => !log.message.includes('LOXONE socket send command')));
    } catch (error) {
      console.log(error);
    }
  };

  if (isLoading) {
    return <p>Loading</p>;
  }
  if (loxoneLogs.length == 0 && otherLogs.length == 0) {
    return <p>No Logs</p>;
  }

  // print logs

  return (
    <Box width={1200} height={500} overflow="auto">
      <h3>Loxone Logs</h3>
      {loxoneLogs.map((log: any) => (
        <p>
          <Typography color="blue">{moment(log.created_at).format('DD/MM - HH:mm:ss')}</Typography>{' '}
          <span> {log.message}</span>
        </p>
      ))}

      {/* <h3>Other Logs</h3>
      {otherLogs.map((log: any) => (
        <p>
          {log.created_at} - {log.message}
        </p>
      ))} */}
    </Box>
  );
};

LogView.defaultProps = { label: 'Log' };
