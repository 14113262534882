import { Box } from '@mui/material';
import { TextField } from 'react-admin';

export const CodeView = (props: any) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <TextField source="code" emptyText="No Code" />
      {/* {record.code && record.code !== '' ? (
        <Button
          size="small"
          variant="contained"
          sx={buttonStyles.warning}
          disabled={isLoading}
          onClick={() => setIsShowConfirm(true)}
        >
          Remove
        </Button>
      ) : null}
      <Button size="small" variant="contained" sx={buttonStyles.info} disabled={isLoading} onClick={handleNewCodeClick}>
        New Code
      </Button>
      <Confirm
        isOpen={isShowConfirm}
        loading={isLoading}
        title="Remove code"
        content="Are you sure you want to remove the code?"
        onConfirm={handleRemove}
        onClose={() => setIsShowConfirm(false)}
      /> */}
    </Box>
  );
};

CodeView.defaultProps = { label: 'Code' };
