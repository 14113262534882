import { Button, Typography } from '@mui/material';
import moment from 'moment';
import { useRecordContext, useUpdate } from 'react-admin';
import { buttonStyles } from 'src/services/theme';

export const SendMail = (props: any) => {
  const record = useRecordContext(props);
  const [update, { isLoading, error }] = useUpdate();

  const handleClick = () => {
    update('key', { id: record.id, data: { send_mail: moment().format() }, previousData: record });
  };

  if (error) {
    return <p>ERROR</p>;
  }
  // Check if email is empty or null
  if (!record.email || record.email === '') {
    return <Typography variant="body2">No email address</Typography>;
  }
  // Check if code is empty or null
  if (!record.code || record.code === '') {
    return <Typography variant="body2">No code</Typography>;
  }
  return (
    <Button size="small" variant="contained" sx={buttonStyles.info} disabled={isLoading} onClick={handleClick}>
      Resend checkin mail
    </Button>
  );
};

SendMail.defaultProps = { label: 'Resend Checkin Mail' };
