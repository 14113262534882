import FlightLandIcon from '@mui/icons-material/FlightLand';
import LocalOfferIcon from '@mui/icons-material/LocalOfferOutlined';
import { Card, CardContent } from '@mui/material';
import * as inflection from 'inflection';
import moment from 'moment';
import { FilterList, FilterListItem, FilterLiveSearch, SavedQueriesList, useGetList } from 'react-admin';

const Aside = () => {
  const { data } = useGetList('reservation_status', {
    pagination: { page: 1, perPage: 100 },
  });

  return (
    <Card
      sx={{
        marginTop: '4em',
        display: { xs: 'none', md: 'block' },
        order: -1,
        width: '15em',
        mr: 2,
        alignSelf: 'flex-start',
      }}
    >
      <CardContent sx={{ pt: 1 }}>
        <FilterLiveSearch source="full_name@_ilike,number,confirmation_number,lx_user_uuid" />

        <SavedQueriesList />

        <FilterList label="Arrival" icon={<FlightLandIcon />}>
          <FilterListItem
            label="Today"
            value={{
              'arrival_date@_gte': moment().format('YYYY-MM-DD'),
              'arrival_date@_lte': moment().format('YYYY-MM-DD'),
            }}
          />
          <FilterListItem
            label="Yesterday"
            value={{
              'arrival_date@_gte': moment().add(-1, 'days').format('YYYY-MM-DD'),
              'arrival_date@_lte': moment().add(-1, 'days').format('YYYY-MM-DD'),
            }}
          />
          <FilterListItem
            label="Tomorrow"
            value={{
              'arrival_date@_gte': moment().add(1, 'days').format('YYYY-MM-DD'),
              'arrival_date@_lte': moment().add(1, 'days').format('YYYY-MM-DD'),
            }}
          />
          <FilterListItem
            label="This week"
            value={{
              'arrival_date@_gte': moment().startOf('week').add(1, 'days').format('YYYY-MM-DD'),
              'arrival_date@_lte': moment().add(1, 'weeks').startOf('week').format('YYYY-MM-DD'),
            }}
          />
          <FilterListItem
            label="Last week"
            value={{
              'arrival_date@_gte': moment().subtract(1, 'weeks').startOf('week').add(1, 'days').format('YYYY-MM-DD'),
              'arrival_date@_lte': moment().startOf('week').format('YYYY-MM-DD'),
            }}
          />
        </FilterList>

        <FilterList label="Reservation Status" icon={<LocalOfferIcon />}>
          {data &&
            data.map((record: any) => (
              <FilterListItem label={inflection.humanize(record.name)} key={record.id} value={{ status: record.id }} />
            ))}
        </FilterList>
      </CardContent>
    </Card>
  );
};

export default Aside;
