import { Identifier, Show, SimpleShowLayout, TextField, usePermissions } from 'react-admin';
import { Role_Enum } from 'src/utils/enum';
import { CodeView } from './CodeView';
import { GroupButton } from './GroupButton';
import { LogView } from './LogView';
import { SendMail } from './SendMail';

const KeyViewEmbedded = ({ id }: { id?: Identifier }) => {
  const { permissions } = usePermissions();
  return (
    <Show
      title=" "
      id={id}
      queryOptions={{ refetchInterval: 5000 }}
      sx={{
        'p > span': {
          fontSize: '14px',
        },
      }}
    >
      <SimpleShowLayout>
        <TextField source="id" />
        {permissions === Role_Enum.Master && <TextField source="reservation_id" />}
        <TextField source="confirmation_number" />
        <TextField source="email" emptyText="No Email Address" />
        <TextField source="phone" emptyText="No Phone Number" />
        {permissions === Role_Enum.Master && <CodeView className="codeView" />}
        <SendMail />
        {/* <RemoveNfcButton /> */}
        <GroupButton />

        {permissions === Role_Enum.Master && <LogView />}
      </SimpleShowLayout>
    </Show>
  );
};

export default KeyViewEmbedded;
