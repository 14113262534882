/* eslint react/jsx-key: off */
import { Edit, Identifier, ReferenceInput, SelectInput, SimpleForm } from 'react-admin';

const RoomEditEmbedded = ({ id }: { id?: Identifier }) => {
  return (
    <Edit title=" " id={id}>
      <SimpleForm>
        <ReferenceInput label="Group" source="group_id" reference="group">
          <SelectInput placeholder="Select ..." label="Group" optionText="name" />
        </ReferenceInput>

        <ReferenceInput label="HouseKeeping" source="object_id" reference="object">
          <SelectInput label="Object" optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};

export default RoomEditEmbedded;
